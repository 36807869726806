.mainNav {
	display: none !important;
}
.language {
	display: none !important;
}
.newSearchField {
	display: none !important;
}
.searchFilters {
	display: none !important;
}
.searchOptions {
	display: none !important;
}
.searchActions {
	display: none !important;
}
.highlight {
	display: none !important;
}
.articleAction {
	display: none !important;
}
.shLink {
	display: none !important;
}
.searchForm {
	.resultBlock {
		margin-top: 20px;
		padding-top: 20px;
		border-top: 1px dotted #ccc;
	}
	.results {
		.item {
			padding: 20px;
			border-bottom: 1px dotted #ccc;
			&:last-child {
				border-bottom: 0;
			}
		}
		input.checkbox {
			& + {
				label.checkbox {
					background: none;
				}
			}
		}
		.versions {
			padding: 7px 0 !important;
			background: none;
		}
		.metadata {
			padding: 7px 0 !important;
			.col-md-9 {
				padding: 0;
			}
		}
	}
}
.colophon {
	.container {
		border-top: 1px dotted #ccc;
		background: none;
	}
	a {
		display: none;
	}
}
.brandLogo {
	width: 100%;
}
